<template>
  <div id="control">
    <div class="control-info">
      <span>
        <label>会员等级：</label>
        <i class="el-icon-star-on" v-for="n in starCount" :key="n"></i>
      </span>
      <span>会员到期日：2021-12-31</span>
      <span>
        <a href="###">立即续费</a>
      </span>
      <span v-if="userInfo.loginTime"
        >上次登录时间：{{ userInfo.loginTime }}</span
      >
      <span v-else>首次登录</span>
    </div>
    <div class="control-cont">
      <dl>
        <template v-for="(item, index) in sidebar">
          <dt :key="'first-' + index" @click="handle(item)">
            <img src="@/assets/image/home.png" v-show="item.title == '首页'" />
            <img
              src="@/assets/image/product.png"
              v-show="item.title == '产品搜索'"
            />
            <img
              src="@/assets/image/list.png"
              v-show="item.title == '项目管理'"
            />
            <img
              src="@/assets/image/company.png"
              v-show="item.title == '会员中心'"
            />
            <router-link :to="item.path">{{ item.title }}</router-link>
          </dt>
          <template
            v-if="
              item.children && item.children.length && status == 2 && item.flag
            "
          >
            <dd
              v-for="(subitem, subindex) in item.children"
              :key="'second' + index + subindex"
            >
              <router-link :to="subitem.path">{{ subitem.title }}</router-link>
            </dd>
          </template>
        </template>
      </dl>
      <router-view class="control-cont__view"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: 0,
    };
  },
  props: {
    sidebar: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    starCount() {
      if (this.userInfo) {
        if (this.userInfo.vip > 0) {
          return 5;
        }
      }
      return 3;
    },
  },
  beforeMount() {
    this.$axios.get("/member/enterprisePage").then((res) => {
      if (res.state == 200) {
        // console.log(res.data)
        sessionStorage.setItem("status", res.data.status);
        this.status = sessionStorage.getItem("status");
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  mounted() {
    this.status = sessionStorage.getItem("status");
    if (!this.userInfo) {
      this.$router.push({
        path: "/login",
        query: {
          redirect: this.$router.currentRoute.path,
        },
      });
    }
    let user = JSON.parse(localStorage.user);
    if (user.type === 0) {
      // this.$router.push("/a-company");
    } else if (user.type === 1) {
      this.$router.push("/control/companyb");
    } else if (user.type === 2) {
      this.$router.push("/control/companyc");
    }
  },
  methods: {
    handle(item) {
      item.flag = !item.flag;
    },
  },
};
</script>
<style lang="scss" scoped>
#control {
  width: 1200px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 68px auto 0;
  .control-info {
    height: 120px;
    font-size: 18px;
    color: #333;
    line-height: 24px;
    padding: 30px 0 0;
    display: flex;
    span {
      &:first-child {
        display: flex;
        i {
          color: #fa6400;
          font-size: 24px;
        }
      }
      &:nth-child(2) {
        margin: 0 25px;
      }
      &:nth-child(3) {
        a {
          color: #3883ff;
          text-decoration: none;
        }
      }
      &:last-child {
        margin-left: auto;
      }
    }
  }
  .control-cont {
    flex: 1;
    display: flex;
    justify-content: space-between;
    dl {
      width: 230px;
      border: 1px solid #ededed;
      color: #333;
      padding: 10px 0 20px 30px;
      margin: 0 0 114px;
      dt {
        line-height: 50px;
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 10px 0 0;
        img {
          width: 24px;
          height: 24px;
          // background: #ddd;
          margin: 0 20px 0 0;
        }
        a {
          color: #555;
          text-decoration: none;
          &.active {
            color: #000000;
            // font-weight: bold;
          }
        }
      }
      dd {
        line-height: 40px;
        font-size: 16px;
        padding: 0 0 0 44px;
        a {
          color: #333;
          text-decoration: none;
          &.active {
            color: #3883ff;
          }
        }
      }
    }
    &__view {
      width: 940px;
      ::v-deep.crumbs {
        height: 50px;
        font-size: 16px;
        color: #333;
        line-height: 1;
        display: flex;
        a {
          text-decoration: none;
          color: #333;
        }
        label {
          margin: 0 10px;
        }
      }
    }
  }
}
</style>

