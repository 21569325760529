<template>
  <div class="company-center">
    <member-center :sidebar="sidebar"></member-center>
  </div>
</template>
<script>
import MemberCenter from "../components/memberCenter.vue";
export default {
  components: {
    MemberCenter,
  },
  data() {
    return {
      code: null,
      sidebar: [
        {
          path: "/a-company/company-preserve",
          title: "首页",
          flag: false,
        },
        {
          path: "/product-list",
          title: "产品搜索",
          flag: false,
        },
      ],
    };
  },
  beforeMount() {
    this.$axios.get("/member/enterprisePage").then((res) => {
      if (res.state == 200) {
        sessionStorage.setItem("status", res.data.status);
        this.status = sessionStorage.getItem("status");
        if (this.status == 2) {
          this.sidebar.splice(
            1,
            0,
            {
              path: "/a-company/bid-project",
              title: "项目管理",
              flag: false,
              children: [
                {
                  path: "/a-company/bid-project/bid-project-preserve",
                  title: "投标项目维护",
                },
              ],
            },
            {
              path: "/a-company/company",
              title: "会员中心",
              flag: false,
              children: [
                {
                  path: `/a-company/company/company-preserve`,
                  title: "会员信息",
                },
                {
                  path: "/a-company/company/member",
                  title: "会员续费",
                },
                {
                  path: "/a-company/company/bank-account-info",
                  title: "银行账户信息",
                },
              ],
            }
          );
        }
      } else {
        this.$message.error(res.msg);
      }
    });
  },
};
</script>